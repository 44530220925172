@import 'styles';

.header {
  background-color: #87bdc314;
}

.contentBlock {
  max-width: 99.5rem;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 1024px) {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  @media screen and (max-width: 1680px) {
    max-width: 80rem;
  }
  @media screen and (min-width: 2000px) {
    max-width: 110rem;
  }
}

.pageBanner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 0;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    justify-content: unset;
  }
}

.blueSmallTitle {
  font-family: $font-family-main;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: $primary-color;
  @media screen and (max-width: 1024px) {
    font-size: 16px;
    line-height: 24px;
  }
}

.curateTitle {
  font-family: $font-family-title;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  text-transform: uppercase;
  @media screen and (max-width: 1024px) {
    font-size: 24px;
    line-height: 32px;
  }
}

.registerButton {
  width: fit-content;
  @media screen and (max-width: 1024px) {
    width: 100%;
    margin-top: 1.5rem;
  }
}

.containerHalfParts {
  display: flex;
  gap: 5rem;
  align-items: stretch;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    gap: 1rem;
  }
}

.mainImageContainer {
  width: 49%;
  border-radius: 24px;
  overflow: hidden;
  aspect-ratio: 1;

  img {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}

.mainRightBlock {
  width: 50%;
  padding-top: 52px;
  display: flex;
  flex-direction: column;

  .earnTitle {
    font-family: $font-family-alice;
    font-weight: 400;
    font-size: 5rem;
    line-height: 5.1rem;
    letter-spacing: 0;
    text-align: center;

    .blueTitlePart {
      font-size: 4.5rem;
      line-height: 5rem;
      text-align: center;
      color: $primary-color;
    }
  }

  .subTitle {
    margin-top: 48px;
    font-family: $font-family-main;
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0;
    text-align: center;
    margin-bottom: 70px;
  }

  .whiteRoomContainer {
    margin-top: auto;
    width: 50%;
    aspect-ratio: 1;
    overflow: hidden;
    border-radius: 16px;
    position: relative;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .hiwBtn {
    position: absolute;
    top: 0;
    right: 0;
  }

  .hiwText {
    cursor: pointer;
    font-family: $font-family-main;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0;
    margin-left: 20px;
    color: $primary-color;
    text-decoration: underline;

    @media screen and (max-width: 1441px) {
      font-size: 24px;
      line-height: 36px;
    }
  }

  .arrow {
    transform: translateY(-44px);
  }

  .lookBoardsContainer {
    display: flex;
    position: absolute;
    right: 0;
    bottom: 2rem;
    width: 64%;

    img {
      width: 50%;
      aspect-ratio: 1;
      @media screen and (max-width: 1441px) {
        width: 50%;
        aspect-ratio: 1;
      }
    }

    img + img {
      margin-left: 16px;
    }
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}

.howItWorksSection {
  margin-top: 5rem;
  margin-bottom: 80px;

  .howItWorksTitle {
    margin-bottom: 2.5rem;
    font-weight: 400;
    font-size: 3rem;
    line-height: 3.5rem;
    text-transform: uppercase;
    color: $text-color;
    font-family: $font-family-title;

    span {
      color: $primary-color;
    }
  }

  .stepsContainer {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 2.5rem;
    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }
  }

  .block {
    width: 25%;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  .blockMiddle {
    width: 50%;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  .stepHeader {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: $primary-color;
    border-radius: 28px;
    color: white;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    text-transform: capitalize;
    padding: 0.25rem;
    margin-bottom: 0.5rem;
  }

  .stepNumber {
    border-radius: 50%;
    width: 48px;
    height: 48px;
    background-color: white;
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $text-color;
  }

  .stepTitle {
    flex-grow: 1;
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    text-transform: capitalize;
  }

  .stepImage {
    height: 25rem;
    width: 100%;
    object-fit: cover;
    border-radius: 24px;

    &.mobileStep2 {
      @media screen and (max-width: 1024px) {
        height: 652px;
      }
    }
  }

  .stepGuidList {
    list-style: none;
    padding-left: 0;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .stepGuidList li {
    position: relative;
    padding-left: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;
  }

  .stepGuidList li + li {
    margin-top: 16px;
  }

  .stepGuidList a {
    text-decoration: underline;
    color: $primary-color;
  }

  .highlightText {
    text-decoration: underline;
    color: $primary-color;
    font-weight: bold;
    text-transform: uppercase;
  }

  .stepGuidList li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 8px;
    width: 8px;
    height: 8px;
    background-color: #d9cab6;
    border-radius: 50%;
  }

  .stepButton {
    display: inline-block;
    width: fit-content;
    margin-top: auto;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  .stepButton + .stepButton {
    margin-left: 10px;
  }

  .firstStepButtons {
    display: flex;

    .stepButton {
      width: 50%;
    }
  }
}
